import React from "react"
import { graphql, Link } from "gatsby"
import LayoutGeneral from "../components/organisms/Layout/LayoutGeneral"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { Helmet } from "react-helmet"
import Image from "../components/atoms/Image/Image"
import Paragraph from "../components/atoms/Paragraph/Paragraph"
import { css } from "@emotion/react"
import DuenoForm from "../components/organisms/Form/DuenoForm"
import CardTodaLaVida from "../components/molecules/Card/CardTodaLaVida"
import getUri from "../helper/getUri"

export const query = graphql`
  query ($id: ID!) {
    wordpress {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        template {
          ... on Wordpress_Template_DuenoDeUnArmando {
            duenoDeUnArmando {
              texto
              proyectos {
                datos
                distrito
                linkDeProyecto
                proyecto
                imagen {
                  ...imagen
                }
                imagenMovil {
                  ...imagen
                }
                textoReverso2
                textoReverso1
              }
              linkWhatsapp
              banner {
                ...imagen
              }
              bannerMovil {
                ...imagen
              }
              tituloProyectos
            }
          }
        }
      }
    }
  }
`

export default function DuenoDeUnArmando({ data }) {
  // if (typeof window !== "undefined") {
  //   let bodyHtml = document.querySelector("body")
  //   bodyHtml.classList.remove("no-scroll")
  //   ReactModal.setAppElement("body")
  // }

  const page = data?.wordpress?.page
  const template = page?.template?.duenoDeUnArmando
  const list = template.proyectos

  const sectionTextCss = css`
    background-color: #f5f5f5;
    text-align: center;
    padding-top: calc(100vw * (calc(130 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(59 / var(--width_base))));
    & p,
    h2 {
      margin-bottom: calc(100vw * (calc(45 / var(--width_base))));
    }
    @media (max-width: 767px) {
      & p,
      h2 {
        margin-bottom: calc(100vw * (calc(32 / var(--width_base))));
      }
      padding-top: calc(100vw * (calc(48 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(16 / var(--width_base))));
    }
  `
  const textCss = css`
    font-size: calc(100vw * (calc(47 / var(--width_base))));
    line-height: calc(100vw * (calc(56 / var(--width_base))));
    color: #30302f;
    font-family: "Poppins Light", sans-serif;
    & strong {
      font-family: "Poppins SemiBold", sans-serif;
    }
    & a {
      text-decoration: underline;
    }
    & h2 {
      font-size: calc(100vw * (calc(392 / var(--width_base))));
      line-height: calc(100vw * (calc(338 / var(--width_base))));
      font-family: "Morganite Black", sans-serif;
    }
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(17 / var(--width_base))));
      line-height: calc(100vw * (calc(21 / var(--width_base))));
      & h2 {
        font-size: calc(100vw * (calc(132 / var(--width_base))));
        line-height: calc(100vw * (calc(114 / var(--width_base))));
      }
    }
  `
  const sectionProjectCss = css`
    background-color: #1d1e1c;
    text-align: center;
    padding-top: calc(100vw * (calc(150 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(150 / var(--width_base))));
    background-position-y: calc(100vw * (calc(-3 / var(--width_base))));
    background-position-x: center;
    background-repeat: no-repeat;
    @media (max-width: 767px) {
      padding-top: calc(100vw * (calc(70 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(70 / var(--width_base))));
      background-color: #ffffff;
    }
  `
  const sectionFooterCss = css`
    background-color: #ffffff;
    text-align: center;
    padding-top: calc(100vw * (calc(37 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(37 / var(--width_base))));
    @media (max-width: 767px) {
      padding-top: calc(100vw * (calc(30 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(30 / var(--width_base))));
    }
  `

  const titleProjectCss = css`
    font-size: calc(100vw * (calc(36 / var(--width_base))));
    line-height: calc(100vw * (calc(40 / var(--width_base))));
    color: #ffffff;
    font-family: "Poppins Light", sans-serif;
    margin-bottom: calc(100vw * (calc(55 / var(--width_base))));
    & strong {
      font-family: "Poppins SemiBold", sans-serif;
    }

    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(18 / var(--width_base))));
      line-height: calc(100vw * (calc(21 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(35 / var(--width_base))));
      color: rgb(48, 48, 47);
    }
  `
  const textFooterCss = css`
    font-size: calc(100vw * (calc(21 / var(--width_base))));
    line-height: calc(100vw * (calc(15 / var(--width_base))));
    color: #000;
    font-family: "Poppins Light", sans-serif;
    & span {
      color: #29a3da;
    }
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(12 / var(--width_base))));
      line-height: calc(100vw * (calc(8 / var(--width_base))));
    }
  `
  const textWhatsapp2Css = css`
    font-size: calc(100vw * (calc(20 / var(--width_base))));
    line-height: calc(100vw * (calc(20 / var(--width_base))));
    color: #fff;
    background-color: rgb(255, 114, 128);
    font-family: "Poppins Regular", sans-serif;
    height: calc(100vw * (calc(35 / var(--width_base))));
    width: calc(100vw * (calc(253 / var(--width_base))));
    display: none;
    justify-content: center;
    align-items: center;
    margin: auto;
    border-radius: calc(100vw * (calc(10 / var(--width_base))));
    & img {
      width: calc(100vw * (calc(20 / var(--width_base))));
      margin-right: calc(100vw * (calc(8 / var(--width_base))));
    }
    & span {
      font-family: "Poppins SemiBold", sans-serif;
    }

    @media (max-width: 767px) {
      display: flex;
    }
  `
  const projectsCss = css`
    margin-bottom: calc(100vw * (calc(98 / var(--width_base))));
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-right: auto;
    margin-left: auto;
    max-width: calc(100vw * (calc(1440 / var(--width_base))));
    @media (max-width: 1440px) and (min-width: 1025px) {
      max-width: calc(100vw * (calc(1300 / var(--width_base))));
    }
    @media (max-width: 1024px) and (min-width: 768px) {
      max-width: calc(100vw * (calc(1000 / var(--width_base))));
    }
    @media (max-width: 767px) {
      max-width: calc(100vw * (calc(335 / var(--width_base))));
      flex-wrap: wrap;
      margin-bottom: calc(100vw * (calc(21 / var(--width_base))));
    }
  `
  const img1Css = css`
    display: block;

    @media (max-width: 767px) {
      display: none;
    }
  `
  const img2Css = css`
    display: none;
    @media (max-width: 767px) {
      display: block;
    }
  `
  const formBuena = css`
    display: block;
    margin: auto;
    max-width: calc(100vw * (calc(935 / var(--width_base))));
    @media (max-width: 767px) {
      display: none;
    }
  `

  return (
    <LayoutGeneral>
      <Helmet>
        <link rel="canonical" href={getUri(page.seo.canonical)} />
      </Helmet>
      <Seo post={page} />
      <h1 className="d-none">{page.title}</h1>
      <div className="grid-100 grid-x-0">
        <Image imagen={template.banner} styleCss={img1Css} />
        <Image imagen={template.bannerMovil} styleCss={img2Css} />
      </div>
      <div className="grid-100 grid-x-0" css={sectionTextCss}>
        <Paragraph text={template.texto} styleCss={textCss} />
      </div>
      <div className="grid-100 grid-x-0" css={sectionProjectCss}>
        <Paragraph text={template.tituloProyectos} styleCss={titleProjectCss} />
        <div css={projectsCss}>
          {list &&
            list.map((item, i) => (
              <CardTodaLaVida
                data={item}
                key={i}
                linkWsp={template?.linkWhatsapp}
              />
            ))}
        </div>
        <div css={formBuena}>
          <DuenoForm />
        </div>
        <Link
          to={`${template.linkWhatsapp}`}
          css={textWhatsapp2Css}
          target="_blank"
        >
          <img
            src="https://apros-qa.net.pe/armandoparedes/wp-content/uploads/2022/03/Recurso-7.png"
            alt="Contacto asesor"
          />
          Contactar asesor
        </Link>
      </div>
      <div css={sectionFooterCss}>
        <Link
          to={`https://www.armandoparedes.com/terminosycondiciones/`}
          css={textFooterCss}
        >
          Ver términos y condiciones <span>aquí</span>
        </Link>
      </div>
    </LayoutGeneral>
  )
}
